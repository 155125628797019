<template>
  <div>
    <div class="container">
      <div class="row">
        <div class="col s12 center-align">
          <h5 style="margin-right: 100px">I am a&nbsp;<span style="position: relative"><span class="fontitalic brown-text role-interchange">Composer</span><span class="fontitalic brown-text role-interchange hidden">Live Performer</span><span class="fontitalic brown-text role-interchange hidden">Producer</span></span></h5>
        </div>
      </div>
      <div class="row" style="margin-bottom: 200%">
        <div class="col s12" style="position: relative;">
          <span class="role-interchange-img" style="line-height: 0">

            <img src="https://i.ibb.co/hWWPWJN/Massimo-Cervini-Composer.jpg" class="responsive-img" alt="" ><br>
            <img src="https://i.ibb.co/hWWPWJN/Massimo-Cervini-Composer.jpg" class="responsive-img hide-on-med-and-up" alt="" ><br>
            <img src="https://i.ibb.co/hWWPWJN/Massimo-Cervini-Composer.jpg" class="responsive-img hide-on-med-and-up" alt="" ><br>
            <img src="https://i.ibb.co/hWWPWJN/Massimo-Cervini-Composer.jpg" class="responsive-img hide-on-med-and-up" alt="" >
          
          </span>
          <span class="role-interchange-img" style="line-height: 0">

            <img src="https://i.ibb.co/qnwNmYH/Decostruttori.jpg" alt="" class="responsive-img"><br>
            <img src="https://i.ibb.co/25PRq1d/Th-Morlacchi.jpg" alt="" class="responsive-img hide-on-med-and-up"><br>
            <img src="https://i.ibb.co/hWWPWJN/Massimo-Cervini-Composer.jpg" class="responsive-img hide-on-med-and-up" alt="" ><br>
            <img src="https://i.ibb.co/hWWPWJN/Massimo-Cervini-Composer.jpg" class="responsive-img hide-on-med-and-up" alt="" >
          
          </span>
          <span class="role-interchange-img" style="line-height: 0">

            <img src="https://i.ibb.co/ckCwgr8/Th-Morlacchi-Piano.jpg" alt="" class="responsive-img"><br>
            <img src="https://i.ibb.co/9ym4bhg/1553578-10201915304336663-1771188206-o.jpg" alt="" class="responsive-img hide-on-med-and-up"><br>
            <img src="https://i.ibb.co/hWWPWJN/Massimo-Cervini-Composer.jpg" class="responsive-img hide-on-med-and-up" alt="" ><br>
            <img src="https://i.ibb.co/hWWPWJN/Massimo-Cervini-Composer.jpg" class="responsive-img hide-on-med-and-up" alt="" >
          
          </span>
        </div>
      </div>
    </div>

    

    <div id="menu" class="brown hidden-menu" style="position: fixed; top: 0; left: 0; width: 100vw; height: 100vh; display: flex; align-items: center; text-align: center">
      <div class="container">
        <div class="row">
          <router-link to="/about">
            <div class="navtext col s12 l4">
              <h5 class="white-text titlemain">ABOUT ME</h5>
              <h5 class="white-text titlesec">ABOUT&nbsp;ME</h5>
            </div>
          </router-link>
          <router-link to="/projects">
            <div class="navtext col s12 l4">
              <h5 class="white-text titlemain">PROJECTS</h5>
              <h5 class="white-text titlesec">PROJECTS</h5>
            </div>
          </router-link>
          <router-link to="/contact">
            <div class="navtext col s12 l4">
              <h5 class="white-text titlemain">CONTACT</h5>
              <h5 class="white-text titlesec">CONTACT</h5>
            </div>
          </router-link>
        </div>
      </div>
      
    </div>

  </div>
</template>

<style>

.navtext{
  position: relative;
  text-align: center;
}
.navtext:hover .titlemain{
  transform: scaleY(0);
}
.navtext:hover .titlesec{
  transform: translateX(-50%) translateY(-100%) scaleY(1);
}

.titlesec, .titlemain{
  transition: 0.5s;
}
.titlemain{
  transform-origin: bottom;
}
.titlesec{
  transform-origin: top;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-100%) scaleY(0);
}

.hidden{
  opacity: 0!important;
  transform: translateY(-50px);
}
.hidden-img{
  opacity: 0!important;
}
.role-interchange, .role-interchange-img{
  transition: 1s;
  position: absolute;
  left:0;
  opacity: 1;
  white-space: nowrap;
}
#menu{
  transition: 1s;
}
.hidden-menu{
  transform: translateY(100%);
}

</style>

<script>
export default {
  mounted(){
    /* global $ */

    $(window).scroll(function(){
        var ScrollTop = parseInt($(window).scrollTop());

        if (ScrollTop > 80) {
            document.getElementById('menu').classList.remove('hidden-menu')

        }else{
          if (ScrollTop < 80){
            document.getElementById('menu').classList.add('hidden-menu')

          }
          
        }
    });

    var indexCarouselIndex = 0;
    startHeaderCarousel();

    var imageCarouselIndex = 0;
    startImageCarousel();

    function startHeaderCarousel(){
        var i;
        var carouselitem = document.getElementsByClassName('role-interchange')
        for (i = 0; i < carouselitem.length; i++) {
            carouselitem[i].classList.add('hidden')

        }
        indexCarouselIndex++;
        if (indexCarouselIndex > carouselitem.length) {
            indexCarouselIndex = 1
        }

        carouselitem[indexCarouselIndex-1].classList.remove('hidden')
        setTimeout(startHeaderCarousel, 2000);
    }
    function startImageCarousel(){
        var i;
        var carouselitem = document.getElementsByClassName('role-interchange-img')
        for (i = 0; i < carouselitem.length; i++) {
            carouselitem[i].classList.add('hidden-img')

        }
        imageCarouselIndex++;
        if (imageCarouselIndex > carouselitem.length) {
            imageCarouselIndex = 1
        }

        carouselitem[imageCarouselIndex-1].classList.remove('hidden-img')
        setTimeout(startImageCarousel, 2000);
    }
  }
}
</script>
