<template>
  <!-- <div id="app">
    <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div>
    <router-view/>
  </div> -->
  <div id="app">

    <div class="brown overlay-transition" id="brown-overlay"></div>

    <div v-show="$route.name != 'Home'" class="hide-on-med-and-down"  style="position: fixed; bottom: 0; left: 0; z-index: 99;">
      <div class="row brown-text ">
        <div class="col s0 l3"></div>
        <div class="col s12 l9" style="line-height: 0px">
          <router-link to="/" class="navitem">
            <h6 class="brown-text">Home</h6>
            <span class="underline brown">&nbsp;</span>
          </router-link>
          <router-link to="/about" class="navitem">
            <h6 class="brown-text">About&nbsp;Me</h6>
            <span class="underline brown">&nbsp;</span>
          </router-link>
          <router-link to="/projects" class="navitem">
            <h6 class="brown-text">Projects</h6>
            <span class="underline brown">&nbsp;</span>
          </router-link>
          <router-link to="/contact" class="navitem">
            <h6 class="brown-text">Contact</h6>
            <span class="underline brown">&nbsp;</span>
          </router-link>
        </div>
      </div>
    </div>

    <div class="row white" style="border-bottom: 1px solid lightgray;width: 100%; text-align:center;display: block; z-index: 100; position: fixed; top:-1px; left: 0">
      <div class="col s12">
        <h4 id="logo">M<span class="iconhide">ASSIMO </span>C<span class="iconhide">ERVINI</span></h4>
      </div>
      <div class="col s12 hide-on-med-and-up transparent" style="margin-top: -56px; ">
        <ul class="collapsible z-depth-0 transparent" style="border: 0px!important; ">
          <li style="border: 0px">
            <div class="collapsible-header transparent" style="border: 0px!important; "><i class="material-icons brown-text">menu</i></div>
            <div class="collapsible-body transparent" style="border: 0px!important; ">
              <router-link to="/" class="navitem">
                <h6 class="brown-text">Home</h6>
              </router-link>
              <router-link to="/about" class="navitem">
                <h6 class="brown-text">About&nbsp;Me</h6>
              </router-link>
              <router-link to="/projects" class="navitem">
                <h6 class="brown-text">Projects</h6>
              </router-link>
              <router-link to="/contact" class="navitem">
                <h6 class="brown-text">Contact</h6>
              </router-link>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <p><br><br></p>
    <router-view/>
  </div>
</template>

<style scoped>

.navitem{
  position: relative;
}
.navitem:hover .underline{
  transform: scaleX(100%);
}
.underline{
  display: inline-block;
  width: 100%;
  height: 1px;
  transform: scaleX(0);
  transform-origin: left;
  transition: 0.5s;
}

.iconhide{
  font-family: inherit;
  transition: 0.5s;
  white-space: nowrap;
}
.hidden{
  opacity: 0;
  margin: 0 -20% 0 -20%;
}
#logo{
  transition: 0.5s;
  display: inline-block;
  font-size: 20px;
}

#brown-overlay{
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 100%;
  opacity: 1;
  pointer-events: none;
}
.overlay-transition{
  transition: 2s;
}
.overlay-hide{
  opacity: 0!important;
}
</style>

<style>

*{
  font-family: 'Libre Baskerville', serif;
}
.fontstrong{
  font-weight: 700;
}
.fontitalic{
  font-style: italic;
}

</style>

<script>
export default {
  /* global $ M */
   watch: {
    '$route': function() {
      document.getElementById('brown-overlay').classList.remove('overlay-transition')
      document.getElementById('brown-overlay').classList.remove('overlay-hide')
      setTimeout(() => {
        document.getElementById('brown-overlay').classList.add('overlay-transition')
        document.getElementById('brown-overlay').classList.add('overlay-hide')
      }, 500);
     
    
    }
  },
  mounted(){
    setTimeout(() => {
      document.getElementById('brown-overlay').classList.add('overlay-hide')
    }, 100);

    document.addEventListener('DOMContentLoaded', function() {
      var elems = document.querySelectorAll('.collapsible');
      M.Collapsible.init(elems);
    });

    $(window).scroll(function(){
        var ScrollTop = parseInt($(window).scrollTop());

        if (ScrollTop > 50) {
            let i;
            let texthiddable = document.getElementsByClassName('iconhide')
            for (i = 0; i < texthiddable.length; i++) {
                texthiddable[i].classList.add('hidden')
            }

        }else{
          if (ScrollTop <50){
            let i;
            let texthiddable = document.getElementsByClassName('iconhide')
            for (i = 0; i < texthiddable.length; i++) {
                texthiddable[i].classList.remove('hidden')
            }
          }
          
        }
    });
  }
  
}
</script>