<template>
    <div class="container">
        <div class="row">
            <div class="col s12 m6" v-for="project in projects" :key="project.name">
                <div class="card white darken-1 z-depth-5">
                    <div class="card-image">
                        <img v-bind:src="project.img">
                    </div>
                    <div class="card-content white-text">
                    <span class="card-title brown-text fontitalic">{{project.title}}</span>
                    <p class="black-text hide-on-med-and-down" style="height: 200px">
                        {{project.paragraph}}
                    </p>
                    <p class="black-text hide-on-med-and-up" >
                        {{project.paragraph}}
                    </p>
                    </div>
                    <div class="card-action brown">
                    <a class="white-text fontitalic" style="text-decoration: underline; text-transform: none!important" v-bind:href="project.url">Learn More  <i class="material-icons tiny">launch</i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            projects: []
        }
    },
    /* global $ */
    mounted(){

        let projects = []

        $.get('https://docs.google.com/spreadsheets/d/e/2PACX-1vQmCyoqrhLP9w-uRQbRH_bNnM6IK7DetUoSCystdtLcO5kzWFwmWV2YNz2TVzkFkJmNlnw0DHMrSVrx/pub?gid=0&single=true&output=csv').then(res=>{
            projects = $.csv.toObjects(res).reverse();
            console.log(projects)
        }).then(()=>{
            this.projects = projects
        })
    }
}
</script>